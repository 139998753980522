import CookieConsent, {getCookieConsentValue, Cookies } from "react-cookie-consent";

const CookiePopUp = () => {
    return (
        <CookieConsent
            overlay={true}
            overlayClasses="cookie-overlay"
            location="bottom"
            buttonText="Accept Cookies"
            enableDeclineButton="true"
            declineButtonText="Reject Cookies"
            cookieName="consentInfraClearCookie"
            style={{ color: "#000", fontSize: "14px" }}

            buttonStyle={{ 
                fontSize: "16px", backgroundColor: "#1976d2",
                borderRadius: '5px',
                boxShadow: "0 2px 5px #0b193233",
                color: "#fff",
                height: "40px",
                padding: "0 30px"
            }}
            declineButtonStyle={{ 
            fontSize: "16px", backgroundColor: "#fff",
            borderRadius: '5px',
            boxShadow: "0 2px 5px #0b193233",
            color: "#1976d2",
            height: "40px",
            padding: "0 30px"
            }}
            expires={150}
            >
                <div className="cookie-content">
                    <b>Strictly Necessary Cookies :</b>
                    These cookies allow you to move through our site without having to sign in again at each page and are necessary for our website to function.
                    <br></br>
                    <b>Functional Cookies :</b>
                    These cookies allow us to keep the settings you have set, such as language, time zone, and other preferences and 
                    enable the basic operation of our site. Without these cookies, certain services of our website may not function correctly.
                    <br></br>
                    By clicking Accept, you consent to our use of strictly necessary and functional cookies.
                    By clicking Reject, you do not consent to our use of strictly necessary and functional cookies 
                    and will not be able to use our website or online services.
                    We care about your privacy, please see our 
                    
                    <a className="mx-1 text-primary" href="/privacy-policy"> 
                        Privacy Policy 
                    </a>
                    if you have any questions about how we use your information.
                </div>
        
        
        </CookieConsent>
    
    )
}

export default CookiePopUp;